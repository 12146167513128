import { Component, inject } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from "@angular/material/button";

@Component({
  selector: 'app-contact-us-alert',
  imports: [MatDialogModule, MatButton],
  templateUrl: './contact-us-alert.component.html',
  styleUrl: './contact-us-alert.component.scss'
})
export class ContactUsAlertComponent {
  private dialogRef = inject<MatDialogRef<ContactUsAlertComponent>>(MatDialogRef);


  closeDialog(): void {
    this.dialogRef.close(); // Close the dialog
  }

}
